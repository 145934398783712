var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _vm.activeTabPane === "Additional Privilege"
        ? _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-2",
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.handleAddRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.showConfirmation }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c("a-table", {
            attrs: {
              dataSource: _vm.dataSource,
              columns: _vm.columnsTable,
              bordered: "",
              scroll: { x: "calc(600px - 50%)" },
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectRow
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selectMenu",
                fn: function(text, record) {
                  return [
                    _c("a-select", {
                      staticClass: "w-100",
                      attrs: {
                        options: record.isSearchMenu
                          ? record.menuOptions
                          : _vm.menuOptions,
                        placeholder: _vm.$t("common.select-text", {
                          text: _vm.$t("lbl_menu")
                        }),
                        "show-search": "",
                        "allow-clear": "",
                        "filter-option": false,
                        "dropdown-match-select-width": false,
                        loading: record.menuLoading,
                        "label-in-value": ""
                      },
                      on: {
                        search: function($event) {
                          return _vm.onSearchMenu(record, $event)
                        },
                        change: function($event) {
                          return _vm.onChangeMenu(record, record.key, $event)
                        }
                      },
                      model: {
                        value: record.menu,
                        callback: function($$v) {
                          _vm.$set(record, "menu", $$v)
                        },
                        expression: "record.menu"
                      }
                    })
                  ]
                }
              },
              {
                key: "create",
                fn: function(text, record) {
                  return _c("a-checkbox", {
                    attrs: {
                      checked: record.create,
                      disabled: record.disabled || record.createId === null
                    },
                    on: {
                      change: function(e) {
                        return _vm.handleCheckBoxTable(
                          e.target.checked,
                          record.key,
                          "create"
                        )
                      }
                    }
                  })
                }
              },
              {
                key: "read",
                fn: function(value, record) {
                  return _c("a-checkbox", {
                    attrs: {
                      checked: record.read,
                      disabled: record.disabled || record.readId === null
                    },
                    on: {
                      change: function(e) {
                        return _vm.handleCheckBoxTable(
                          e.target.checked,
                          record.key,
                          "read"
                        )
                      }
                    }
                  })
                }
              },
              {
                key: "update",
                fn: function(value, record) {
                  return _c("a-checkbox", {
                    attrs: {
                      checked: record.update,
                      disabled: record.disabled || record.updateId === null
                    },
                    on: {
                      change: function(e) {
                        return _vm.handleCheckBoxTable(
                          e.target.checked,
                          record.key,
                          "update"
                        )
                      }
                    }
                  })
                }
              },
              {
                key: "delete",
                fn: function(value, record) {
                  return _c("a-checkbox", {
                    attrs: {
                      checked: record.delete,
                      disabled: record.disabled || record.deleteId === null
                    },
                    on: {
                      change: function(e) {
                        return _vm.handleCheckBoxTable(
                          e.target.checked,
                          record.key,
                          "delete"
                        )
                      }
                    }
                  })
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }