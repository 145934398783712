


















































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { UserMenuState } from "@/models/interface/user";
import { menuService } from "@/services/menu.service";
import { LabelInValue } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    propsDataSource: {
      type: Array as () => UserMenuState[],
      required: true,
    },
    activeTabPane: {
      type: String,
    },
  },
});

@Component({
  mixins: [MNotificationVue],
})
export default class TableRole extends Props {
  dataSource: UserMenuState[] = [];
  selectedRowKeys: number[] = [];
  deleteAddPrivilegeIds: string[] = [];

  menuOptions: Option[] = [];

  columnsTable = [
    {
      title: this.$t("lbl_menu"),
      dataIndex: "idMenu",
      key: "idMenu",
      width: 250,
      scopedSlots: { customRender: "selectMenu" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 250,
    },
    {
      title: this.$t("lbl_create"),
      dataIndex: "create",
      key: "create",
      width: 150,
      scopedSlots: { customRender: "create" },
      align: "center",
    },
    {
      title: this.$t("lbl_read"),
      dataIndex: "read",
      key: "read",
      width: 150,
      scopedSlots: { customRender: "read" },
      align: "center",
    },
    {
      title: this.$t("lbl_edit"),
      dataIndex: "update",
      key: "update",
      width: 150,
      scopedSlots: { customRender: "update" },
      align: "center",
    },
    {
      title: this.$t("lbl_delete"),
      dataIndex: "delete",
      key: "delete",
      width: 150,
      scopedSlots: { customRender: "delete" },
      align: "center",
    },
  ];

  created() {
    this.setMenuOptions();
  }

  async setMenuOptions() {
    this.menuOptions = await this.getMenuList();
  }

  @Watch("propsDataSource", {
    immediate: true,
  })
  onChangePropsDataSource(newValue: UserMenuState[]): void {
    this.dataSource = [...newValue];
  }

  onChangeMenu(
    record: UserMenuState,
    index: number,
    value: LabelInValue | undefined
  ): void {
    const options = record.isSearchMenu ? record.menuOptions : this.menuOptions;
    const selectedMenu = options.find(item => item.key === value?.key);
    // set menu dengan id / value
    const { dataSource } = this;
    this.dataSource[index] = {
      ...dataSource[index],
      menu: value,
      description: selectedMenu?.meta?.description ?? "",
      create: !!selectedMenu?.meta?.create,
      read: !!selectedMenu?.meta?.read,
      update: !!selectedMenu?.meta?.update,
      delete: !!selectedMenu?.meta?.delete,
      createId: selectedMenu?.meta?.create,
      readId: selectedMenu?.meta?.read,
      updateId: selectedMenu?.meta?.update,
      deleteId: selectedMenu?.meta?.delete,
    };
    this.emitChange("input");
  }

  async handleCheckBoxTable(
    value: boolean,
    key: number,
    col: "create" | "read" | "update" | "delete"
  ): Promise<void> {
    this.dataSource[key][col] = value;
    if (value) {
      const res = await menuService.listMenus({
        search: `secureId~${this.dataSource[key].key}`,
      });
      const { data } = res;
      const [menu] = data;
      this.dataSource[key][`${col}Id`] = menu[col] ?? null;
    } else {
      this.dataSource[key][`${col}Id`] = null;
    }
    this.emitChange("input");
  }

  emitChange(action: "addRow" | "deleteRow" | "input"): void {
    this.$emit("change:data-source", {
      action,
      dataSource: this.dataSource,
      deletedIds: this.deleteAddPrivilegeIds,
    });
    this.$forceUpdate(); // force re render ui
  }

  onSelectRow(selectedRowKeys: number[]): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  handleAddRow(): void {
    const { dataSource } = this;
    const newRow: UserMenuState = {
      id: "",
      description: null,
      create: false,
      read: false,
      update: false,
      delete: false,
      createId: null,
      readId: null,
      updateId: null,
      deleteId: null,
      key: dataSource.length,
      disabled: false,
      menuOptions: [],
      isSearchMenu: false,
      menuLoading: false,
      menu: undefined,
    };
    this.dataSource = [...dataSource, newRow];
    this.emitChange("addRow");
  }

  showConfirmation(): void {
    if (!this.selectedRowKeys.length) {
      this.showNotifError("lbl_modal_delete_error_description");
      return;
    }
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", {
        count: this.selectedRowKeys.length,
      }),
      onOk: this.handleDeleteRow,
    });
  }

  handleDeleteRow(): void {
    const { dataSource } = this;
    // delete row
    this.dataSource = dataSource.filter(data => {
      if (data.id && this.selectedRowKeys.includes(data.key)) {
        this.deleteAddPrivilegeIds.push(data.id);
      }
      return !this.selectedRowKeys.includes(data.key);
    });
    // kembalikan key sesuai urutan indexnya
    this.dataSource.forEach((data, index) => (data.key = index));
    this.selectedRowKeys = [];
    this.emitChange("deleteRow");
  }

  async getMenuList(params?: RequestQueryParamsModel): Promise<Option[]> {
    const response = await menuService.listMenus(params);
    return response.data.map(item => ({
      label: item.name,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  }

  onSearchMenu(record: UserMenuState, value?: string): void {
    record.isSearchMenu = true;
    debounce(async () => {
      const params = new RequestQueryParams();
      if (value) {
        params.search = new SearchBuilder()
          .push(["name", value], { like: "both" })
          .or()
          .push(["description", value], { like: "both" })
          .build();
      }
      record.menuLoading = true;
      const response = await this.getMenuList(params);
      record.menuOptions = response;
      record.menuLoading = false;
    });
  }
}
